import { useMemo } from 'react';
import toast from 'react-hot-toast';
import {
  createClient,
  Provider as GQLProvider,
  cacheExchange,
  fetchExchange,
  mapExchange,
  CombinedError,
} from 'urql';

import useAuth from './auth/useAuth';

export const GqlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { jwt, tokenRefresh, logout } = useAuth();

  const client = useMemo(
    () =>
      createClient({
        url: (import.meta.env.VITE_REACT_APP_API as string) + '/graphql',
        fetchOptions: () => ({
          headers: { Authorization: jwt ? `Bearer ${jwt}` : '' },
        }),
        exchanges: [
          cacheExchange,
          mapExchange({
            onError: async (error: CombinedError, operation) => {
              const isJwtStale = (jwt: string): boolean => {
                const payload = JSON.parse(atob(jwt.split('.')[1]));
                const expiration = payload.exp * 1000;
                return Date.now() >= expiration;
              };

              if (isJwtStale(jwt)) {
                tokenRefresh();
              } else if (
                error.message.includes('Unauthorized') ||
                error.message.includes('No Content')
              ) {
                logout();
              } else {
                console.error('error', error);
                // Sentry.captureException(message.toString());
                toast.error(error.message?.toString()?.replace('[GraphQL]', '').trim());
              }
            },
          }),
          fetchExchange,
        ],
      }),
    [jwt]
  );
  return <GQLProvider value={client}>{children}</GQLProvider>;
};
