import { createContext, ReactNode, useEffect, useReducer } from 'react';

// ----------------------------------------------------------------------

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type AuthUser = null | Record<string, any>;

type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  jwt: string;
  refreshToken: string;
};

type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  jwt: string;
  login: (username: string, password: string, secret: string) => Promise<void>;
  tokenRefresh: () => Promise<void>;
  logout: VoidFunction;
  setJwt: (jwt: string) => Promise<void>;
  setRefreshToken: (refreshToken: string) => Promise<void>;
};

// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  jwt: '',
  refreshToken: '',
};

enum Types {
  init = 'INITIALIZE',
  login = 'LOGIN',
  tokenRefresh = 'TOKEN_REFRESH',
  logout = 'LOGOUT',
  setJwt = 'SET_JWT',
  setRefreshToken = 'SET_REFRESH_TOKEN',
}

type Auth0AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.login]: {
    user: AuthUser;
  };
  [Types.tokenRefresh]: undefined;
  [Types.logout]: undefined;
  [Types.setJwt]: {
    jwt: string;
  };
  [Types.setRefreshToken]: {
    refreshToken: string;
  };
};

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>];

const reducer = (state: AuthState, action: Auth0Actions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === Types.login) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === Types.tokenRefresh) {
    return { ...state };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === Types.setJwt) {
    return {
      ...state,
      jwt: action.payload.jwt,
    };
  }
  return state;
};

const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const jwtLocalStorage = localStorage.getItem('jwt-admin');

        if (jwtLocalStorage) {
          var jwt = jwtLocalStorage;
          setJwt(jwt!);
          dispatch({
            type: Types.init,
            payload: { isAuthenticated: true, user: null },
          });
        } else {
          dispatch({
            type: Types.init,
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.init,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string, secret: string) => {
    try {
      const response = await fetch(import.meta.env.VITE_REACT_APP_API + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          secret,
        }),
      });

      if (!response.ok) {
        // If the response is not OK, try to parse it as text
        const errorText = await response.text();
        throw new Error(errorText || response.statusText);
      }

      const tokens = await response.json();

      setJwt(tokens.token);
      setRefreshToken(tokens.refreshToken);

      dispatch({ type: Types.login, payload: { user: tokens || null } });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const tokenRefresh = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken-admin');
      const response = await fetch(import.meta.env.VITE_REACT_APP_API + '/refreshtoken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const tokens = await response.json();

      setJwt(tokens.token);
      setRefreshToken(tokens.refreshToken);
    } catch (error) {
      console.error(error);
      logout();
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('jwt-admin');
    localStorage.removeItem('refreshToken-admin');
    window.location.href = '/sign-in';
    dispatch({ type: Types.logout });
  };

  const setJwt = async (jwt: any) => {
    localStorage.setItem('jwt-admin', jwt);
    dispatch({ type: Types.setJwt, payload: { jwt: jwt } });
  };

  const setRefreshToken = async (refreshToken: string) => {
    localStorage.setItem('refreshToken-admin', refreshToken);
    dispatch({ type: Types.setRefreshToken, payload: { refreshToken } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        jwt: state?.jwt,
        login,
        tokenRefresh,
        logout,
        setJwt,
        setRefreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
