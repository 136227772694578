// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  faqs: '/faqs',
  minimalStore: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    signIn: `${ROOTS.AUTH}/sign-in`,
    signUp: `${ROOTS.AUTH}/sign-up`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    organizations: `${ROOTS.DASHBOARD}/organizations`,
    logs: `${ROOTS.DASHBOARD}/logs`,
    urgentFailures: `${ROOTS.DASHBOARD}/urgent-failures`,
    transactionsToApprove: `${ROOTS.DASHBOARD}/transactions-to-approve`,
  },
};
