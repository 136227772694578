import 'src/global.css';

// ----------------------------------------------------------------------

import { Toaster } from 'react-hot-toast';

import { Router } from 'src/routes/router';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import { ThemeProvider } from 'src/theme/theme-provider';

import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from './auth/AuthContext';
import { GqlProvider } from './GqlProvider';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://2abb2ace18fb39b5b1df433853a1ad53@o1353374.ingest.us.sentry.io/4508206800830464',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Environment
  environment:
    import.meta.env.VITE_REACT_APP_STAGING === 'true'
      ? 'staging'
      : import.meta.env.VITE_REACT_APP_SANDBOX === 'true'
        ? 'sandbox'
        : 'production',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <SettingsProvider settings={defaultSettings}>
        <ThemeProvider>
          <GqlProvider>
            <MotionLazy>
              <ProgressBar />
              <SettingsDrawer />
              <Router />
              <Toaster />
            </MotionLazy>
          </GqlProvider>
        </ThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}
